import { render, staticRenderFns } from "./DemoSwitchInset.vue?vue&type=template&id=5a64c232&"
import script from "./DemoSwitchInset.vue?vue&type=script&lang=js&"
export * from "./DemoSwitchInset.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VSwitch})
