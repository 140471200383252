import { render, staticRenderFns } from "./DemoSwitchLabelSlot.vue?vue&type=template&id=29f34e50&"
import script from "./DemoSwitchLabelSlot.vue?vue&type=script&lang=js&"
export * from "./DemoSwitchLabelSlot.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VProgressCircular,VSwitch})
